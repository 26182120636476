var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-3","height":"auto"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"dark":"","background-color":"grey darken-2"}},[_c('v-tab',{attrs:{"to":{
                name: `module-drum-${_vm.linkName}-individual`,
              }}},[_vm._v("Overview")]),_c('v-tab',{attrs:{"to":{
                name: `module-drum-${_vm.linkName}-individual-itineraries`,
              }}},[_vm._v("Itineraries")]),_c('v-tab',{attrs:{"to":{
                name: `module-drum-${_vm.linkName}-individual-golfers`,
              }}},[_vm._v("Golfers")])],1)]},proxy:true}])},[_c('v-list-item',{staticClass:"pl-0 pr-0",attrs:{"three-line":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title text-wrap"},[_vm._v(" "+_vm._s(_vm.tour.name)+" ")]),_c('v-list-item-subtitle',[_c('router-link',{attrs:{"to":{
                      name: 'module-drum-customers-profile',
                      params: { customerId: _vm.tour.lead_customer.id },
                    }}},[_vm._v(_vm._s(_vm.tour.lead_customer.full_name))])],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.tour.lead_customer.email)+" ")])],1)],1),_c('v-col',{staticClass:"text-right pt-3",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"dark":"","small":"","depressed":""},on:{"click":function($event){return _vm.$refs.proposalForm.openForm()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Edit ")],1)],1)],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"pa-5"},[_c('router-view')],1)],1)],1),_c('ProposalForm',{ref:"proposalForm",attrs:{"isEditing":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }