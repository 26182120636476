<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-card outlined class="mt-2">
        <v-toolbar flat color="grey lighten-3" height="auto">
          <v-list-item class="pl-0 pr-0" three-line>
            <v-row no-gutters>
              <v-col cols="8">
                <v-list-item-content>
                  <v-list-item-title class="title text-wrap">
                    {{ tour.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <router-link
                      :to="{
                        name: 'module-drum-customers-profile',
                        params: { customerId: tour.lead_customer.id },
                      }"
                      >{{ tour.lead_customer.full_name }}</router-link
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ tour.lead_customer.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
              <v-col cols="4" class="text-right pt-3">
                <v-btn
                  dark
                  small
                  depressed
                  @click="$refs.proposalForm.openForm()"
                >
                  <v-icon left>mdi-pencil</v-icon>Edit
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item>
          <template v-slot:extension>
            <v-tabs dark background-color="grey darken-2">
              <v-tab
                :to="{
                  name: `module-drum-${linkName}-individual`,
                }"
                >Overview</v-tab
              >
              <v-tab
                :to="{
                  name: `module-drum-${linkName}-individual-itineraries`,
                }"
                >Itineraries</v-tab
              >
              <v-tab
                :to="{
                  name: `module-drum-${linkName}-individual-golfers`,
                }"
                >Golfers</v-tab
              >
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="pa-5">
          <router-view></router-view>
        </div>
      </v-card>
    </v-container>
    <ProposalForm ref="proposalForm" :isEditing="true" />
  </div>
</template>

<script>
import ProposalForm from "../components/ProposalForm";

export default {
  components: {
    ProposalForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [],
    };
  },

  computed: {
    tour() {
      return this.$store.state.drum.tours["tour"];
    },
    linkName() {
      return this.tour.status === "tour" ? "tours" : "proposals";
    },
  },

  watch: {
    tour() {
      this.setBreadcrumbs();
    },
  },

  created() {
    this.setBreadcrumbs();
  },

  methods: {
    setBreadcrumbs() {
      this.breadcrumbs = [];

      if (this.tour.status === "tour") {
        this.breadcrumbs.push({
          text: "Tours",
          disabled: false,
          exact: true,
          to: {
            name: "module-drum-tours",
            params: { id: this.$route.params.id },
          },
        });
      } else {
        this.breadcrumbs.push({
          text: "Proposals",
          disabled: false,
          exact: true,
          to: {
            name: "module-drum-proposals",
            params: { id: this.$route.params.id },
          },
        });
      }

      this.breadcrumbs.push({
        text: this.tour.name,
        disabled: true,
      });
    },
  },
};
</script>
